<script>

import {
  axiosApi
} from '@/services/axios';

// import ConvertAnchorTag from "@/components/convert-anchor-tag";

import moment from 'moment';

/**
 * Recent activity component
 */
export default {
  components: {
    // ConvertAnchorTag
  },
  async created() {
    this.loading = true
    // await new Promise(resolve => setTimeout(resolve, 5500));
    await axiosApi.get("/activity/recent").then(response => {
      response.forEach((item) => {
        let actData = {}
        actData.icon = this.activityIconMap(item.action)
        actData.date = moment(item.created_at).format("D MMM, YYYY")
        actData.time = moment(item.created_at).format("hh:mm a")
        actData.title = item.title
        this.activityData.push(actData)
      });
      this.loading = false
    })
  },
  data() {
    return {
      loading: false,
      // activityIconMap: {
      //   'profile-updated': 'fas fa-user-edit',
      //   'track-created': 'ri-folder-music-fill',
      //   'track-updated': 'ri-file-music-fill',
      //   'campaign-created': 'ri-global-line',
      //   'campaign-updated': 'ri-global-line',
      //   'campaign-renewed': 'ri-repeat-2-fill',
      //   'campaign-ended': 'ri-global-line',
      //   'campaign-track-submitted': 'ri-play-list-fill',
      //   'campaign-track-acquired': 'ri-play-list-add-fill',
      //   'campaign-track-declined': 'ri-stop-mini-fill'
      // },
      activityData: []
    };
  },
  methods: {
    openCampaign: function (campaignID, campaignTitle) {
      this.$router.push({
        path: '/campaign',
        query: {
          id: campaignID,
          title: campaignTitle
        }
      })
    }
  }
};
</script>

<template>
  <div class="card">

    <div class="card-body">
      <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <!-- item-->
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Export Report</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Profit</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown>

      <h4 class="card-title mb-4">Recent Activity Feed</h4>

      <div data-simplebar style="max-height: 70vh;">

        <b-spinner v-if="loading" type="grow" class="ml-2" variant="light" role="status"></b-spinner>
        <b-spinner v-if="loading" type="grow" class="ml-1" variant="light" role="status"></b-spinner>
        <b-spinner v-if="loading" type="grow" class="ml-1" variant="light" role="status"></b-spinner>

        <ul class="list-unstyled activity-wid">
          <li class="activity-list" v-for="(data, index) in activityData" :key="index">
            <div class="activity-icon avatar-xs">
              <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                <i :class="`${data.icon}`"></i>
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13">
                  {{data.date}}
                  <small class="text-muted">{{data.time}}</small>
                </h5>
              </div>

              <div>
                <!-- <convert-anchor-tag :htmlContent="data.title"> -->
                <p class="text-muted mb-0" v-html="data.title"></p>
                    <!-- <div v-html="htmlContent"></div> -->
                <!-- </convert-anchor-tag> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
