<script>

import {
  axiosApi
} from '@/services/axios';

/**
 * Widget Component
 */
export default {
  async created() {
    this.loading = true
    await new Promise(resolve => setTimeout(resolve, 1500));
    await axiosApi.get("/analytics/dashboard-stats").then(response => {
      this.statData[0].value = this.abbreviateNumber(response.reach);
      this.statData[1].value = response.acqs.toString();
      this.statData[2].value = `% ${response.acqsScore.toString()}`;
      this.loading = false
    })
  },
  data() {
    return {
      loading: false,
      statData: [
        {
          title: "Audience",
          icon: "ri-team-line",
          value: "~",
          subvalue: " 2.4% "
        },
        {
          title: "Acquisitions",
          icon: "ri-play-list-line",
          value: "~",
          subvalue: " 2.4% "
        },
        {
          title: "Average Acq Score",
          icon: "ri-flag-line",
          value: "~",
          subvalue: " 2.4% "
        }
      ]
    };
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <b-spinner v-if="loading" type="grow" style="width: 1.35rem; height: 1.35rem;" variant="light" role="status"></b-spinner>
              <h4 v-else class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <!-- <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}}
            </span>
            <span class="text-muted ml-2">From previous period</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
