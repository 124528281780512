<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import ModalNewCampaign from "@/components/modal-new-campaign";

import appConfig from "@/app.config";

import Swal from "sweetalert2";

import Stat from "./widget";
import RecentActivity from "./recent-activity";
import YourCampaigns from "./your-campaigns";


import {
  axiosApi
} from '@/services/axios';

/**
 * Dashboard component
 */
export default {
  page: {
    title: "Artist Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    RecentActivity,
    YourCampaigns,
    ModalNewCampaign
  },
  beforeCreate: async function () {
    await axiosApi.get('/dashboard')
  },
  created: function () {
    if (location.hash.startsWith('#new-campaign-success')) this.newCampaignSuccess()
    if (location.hash.startsWith('#spotify-import-success')) this.spotifyImportSuccess()
  },
  mounted: function () {
    const script = document.createElement("script");
    script.id="hs-script-loader"
    script.src="//js.hs-scripts.com/22679414.js";
    document.body.appendChild(script);
  },
  data() {
    return {
      title: "Dashboard",
      renderKey: 0,
      analyticsLoaded: false,
      items: [
        {
          text: "99subs"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      steps: [
        {
          target: '.v-step-0', // We're using document.querySelector() under the hood
          header: {
            title: 'Get Started',
          },
          content: `Discover <strong>Vue Tour</strong>!`
        },
        {
          target: '.v-step-1',
          content: 'An awesome plugin made with Vue.js!'
        },
        {
          target: '[.v-step-2]',
          content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
          params: {
            placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        }
      ],
    };
  },
  methods: {
    openCampaign(campaignID, campaignTitle) {
      this.$router.push({
        path: '/campaign',
        query: {
          id: campaignID,
          title: campaignTitle
        }
      })
    },
    spotifyImportSuccess () {
      window.location.hash = ''
      window.history.pushState(null, "", window.location.href.replace("#", ""));
      this.$router.push({
        path: '/tracks',
        hash: '#spotify-import-success'
      })
    },
    async newCampaignSuccess() {
      const currentTrack = await axiosApi.get(`/tracks/${this.$route.query.trackID}?fields=id name images`).catch((err) => {
        console.error(err);
        window.location.hash = ''
        window.history.replaceState({}, document.title, "/");
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Don\'t worry, we have been notified and are working on it.'
        })
      })

      const result = await axiosApi.post(`/campaigns?trackID=${currentTrack.id}`).catch((err) => {
        console.error(err);
        window.location.hash = ''
        window.history.replaceState({}, document.title, "/");
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Don\'t worry, we have been notified and are working on it.'
        })
      })

      if (!result) {
        window.location.hash = ''
        window.history.replaceState({}, document.title, "/");
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Don\'t worry, we have been notified and are working on it.'
        })
      }

      Swal.fire({
        icon: "success",
        title: 'Campaign Started!',
        text: currentTrack.name,
        imageUrl: currentTrack.images[0].url,
        allowOutsideClick: true,
        showConfirmButton: true,
        confirmButtonText: 'Bravo!'
      })
      window.location.hash = ''
      window.history.replaceState({}, document.title, "/");
      this.renderKey += 1;
    },

  }
};
</script>

<template>
<Layout>
  <!-- <v-tour name="introTour" :steps="steps" :options="{ highlight: true }"></v-tour> -->
  <ModalNewCampaign />
  <PageHeader class="v-step-0"
    :title="title"
    :items="items"
    :key="renderKey"
  />
  <div class="row">
    <div class="col-xl-8">
      <Stat v-once />
      <YourCampaigns :key="renderKey"/>
    </div>
    <div class="col-xl-4">
      <RecentActivity :key="renderKey"/>
    </div>
  </div>


</Layout>
</template>

<style>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4);
}

body.swal2-shown>[aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

body>* {
  transition: 0.15s filter linear;
}
</style>
