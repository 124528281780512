<script>

import {
  axiosApi
} from '@/services/axios';

/**
 * Widget Component
 */
export default {
  async created() {
    this.loading = true
    // await new Promise(resolve => setTimeout(resolve, 2500));
    await axiosApi.get("/campaigns?fields=name id img&limit=20&sort=created_at:-1").then(response => {
      this.yourCampaignData = response;
      this.loading = false
    })
  },
  data() {
    return {
      loading: false,
      yourCampaignData: [],
    };
  },
  methods: {
    openCampaign: function (campaignID, campaignTitle) {
      this.$router.push({
        path: '/campaign',
        query: {
          id: campaignID,
          title: campaignTitle
        }
      })
    }
  }
};
</script>

<template>
  <div class="card v-step-1">
    <div class="card-body">
      <i id="new-campaign" v-b-modal.modal-new-campaign class="fas fa-globe fa-lg float-right"></i>
      <b-tooltip target="new-campaign" placement="bottom">New Campaign</b-tooltip>
      <!-- <b-dropdown
        right
        toggle-class="arrow-none card-drop"
        class="float-right"
        variant="white"
      >
        <template v-slot:button-content>
          <i id="new-campaign" v-b-modal.modal-new-campaign class="mdi mdi-layers-plus"></i>
        </template> -->
        <!-- item-->
        <!-- <b-dropdown-item  class="v-step-2" v-b-modal.modal-scrollable>New Campaign</b-dropdown-item> -->

        <!-- item-->
        <!-- <b-dropdown-item>Export Report</b-dropdown-item> -->
        <!-- item-->
        <!-- <b-dropdown-item>Profit</b-dropdown-item> -->
        <!-- item-->
        <!-- <b-dropdown-item>Action</b-dropdown-item> -->
      <!-- </b-dropdown> -->

      <h3 class="card-title">Your Campaigns</h3>
      <p class="card-title-desc">
        Active tracks on campaign we are promoting for you.
      </p>

      <div
        class="row mb-3"
      >
        <b-spinner v-if="loading" type="grow" class="ml-2" variant="light" role="status"></b-spinner>
        <b-spinner v-if="loading" type="grow" class="ml-1" variant="light" role="status"></b-spinner>
        <b-spinner v-if="loading" type="grow" class="ml-1" variant="light" role="status"></b-spinner>

        <div v-else v-for="campaign in yourCampaignData" class="col-md-4 text-center" :key="campaign.id">
          <a @click.prevent="openCampaign(campaign.id, campaign.name)">
            <img
              class="img-thumbnail"
              alt="200x200"
              width="400"
              :src="campaign.img"
              data-holder-rendered="true"
            />
            <h6 class="text-truncate mt-2 mb-3">{{ campaign.name }}</h6>
          </a>
        </div>
      </div>

    </div>
  </div>
  <!-- end row -->
</template>

<style scoped>
  a {
    cursor: pointer !important;
  }
</style>
