<script>

import {
  axiosApi
} from '@/services/axios';

/**
 * Widget Component
 */
export default {
  created() {
    axiosApi.get("/tracks?qAdd=on_campaign:false&fields=name id images on_campaign campaign_id platform created_at metadata.external_urls.spotify&limit=16&sort=created_at:-1").then(response => {
      this.tracksData = []
      response.forEach((item) => {
        let trackData = {}
        trackData.id = item.id
        trackData.img = item.images[0].url
        trackData.name = item.name
        trackData.href = item.metadata.external_urls.spotify
        trackData.platform = item.platform
        trackData.icon = this.platformIconMap(item.platform)
        trackData.onCampaign = item.on_campaign
        trackData.campaignID = item.campaign_id
        this.tracksData.push(trackData)
      });
    })
  },
  data() {
    return {
      tracksData: [],
      loading: false
    };
  },
  methods: {
    startNewCampaign: function (trackID, trackName) {
      this.$router.push({
        path: '/campaign-checkout',
        query: {
          trackID: trackID,
          trackName: trackName
        }
      })
    },
  }
};
</script>

<template>
  <b-modal
    id="modal-new-campaign"
    scrollable
    hide-footer
    size="xl"
    title="Pick a Track for this Campaign"
    title-class="font-18"
  >
  <div class="row">
    <div class="col-xl-3 col-sm-6" v-for="(data, index) in tracksData" :key="index">
      <div class="text-center">
        <div class="image-button-container">
          <img @click="startNewCampaign(data.id, data.name)" :src="`${data.img}`" alt class="avatar-xl mt-2 mb-2 img-thumbnail" role="button"/>
        </div>
        <div class="media-body">
          <h6 class="text-truncate" @click.prevent="startNewCampaign(data.id, data.name)" role="button">{{ data.name }}
          </h6>
          <div class="mb-3">
            <b-tooltip :target="`view-platform-${index}`" placement="bottom">Listen on {{ data.platform }}</b-tooltip>
            <a :href="data.href" target="_blank"><i :id="`view-platform-${index}`" :class="data.icon" class="fa-lg mr-2 text-success"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  </b-modal>
</template>

<style scoped>
.avatar-xl {
  height: 15rem;
  width: 15rem;
}

.badge-position {
  position: relative;
  top: -2px;
}

.activity-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.image-button-container {
  position: relative;
}

.image-button-container > button {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

</style>
